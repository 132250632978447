import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ImageGrid = makeShortcode("ImageGrid");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "other-ways-to-help-your-teenager-manage-anxiety"
    }}>{`Other ways to help your teenager manage anxiety`}</h1>
    <h3 {...{
      "id": "praise"
    }}>{`Praise!`}</h3>
    <p>{`Reward and praise your teenager for facing their fears and taking steps to manage their anxiety. Tell your teenager that you are proud of them! It isn’t easy facing one’s fears. Also, encourage your teenager to reward and praise themselves whenever they tackle situations that make them anxious.`}</p>
    <h3 {...{
      "id": "be-supportive"
    }}>{`Be supportive.`}</h3>
    <p>{`Recognise that it is difficult for your teenager to face their fears. It is important not to laugh at your teenager or minimise their fears (for example, by saying "don't be silly" or "you're being stupid"). If you find yourself becoming impatient with your teenager’s anxiety, remind yourself of how overwhelming it can feel to face your own fears.`}</p>
    <ImageGrid list={[{
      src: '/images/m9/43-01.svg'
    }, {
      src: '/images/m9/43-02.svg'
    }, {
      src: '/images/m9/43-03.svg'
    }]} mdxType="ImageGrid" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      